import React, { useEffect, useState, useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import PubSub from "pubsub-js";
import styled from "styled-components";
import clsx from "clsx";
import { Tween } from "react-gsap";
import { pad2, _linkResolver } from "../core/utils";

import { WrapperContext } from "./Layout";

const query = graphql`
  query {
    prismicMenu(uid: { eq: "menu_category" }) {
      data {
        menu_items {
          item {
            type
            uid
          }
          title {
            text
          }
          margin
        }
      }
    }
    allPrismicProject(sort: { fields: data___order }) {
      # allPrismicProject {
      nodes {
        data {
          title {
            text
          }
          client
          year
          link_label
          link {
            url
          }
          categories {
            category {
              uid
              document {
                ... on PrismicCategory {
                  id
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  nav {
    pointer-events: none;
  }
  &.is-active {
    pointer-events: all;
    nav {
      opacity: 1;
      pointer-events: all;
      // position: initial;
    }
  }
  button {
    text-align: left;
  }
  @media all and (min-width: 1024px) {
    .btn-toggle-wrapper {
      height: 22px;
    }
  }
  nav {
    opacity: 0;
    pointer-events: none;
    // opacity: 1;
    // li {
    //   opacity: 1 !important;
    // }
  }
`;

const Nav = styled.nav`
  li {
    white-space: nowrap;
  }
`;

const MenuCategory = () => {
  const { template, currentCategoryTitle, direction } =
    useContext(WrapperContext);
  // console.log(template, currentCategoryTitle);

  const { prismicMenu, allPrismicProject } = useStaticQuery(query);
  const [active, setActive] = useState(false);
  const [currentCategory, setCurrentCategory] = useState();

  const duration = 0.4;
  // const ease = "easeInOutCubic";
  const ease = "ease";

  useEffect(() => {
    const token = PubSub.subscribe("ROUTE_UPDATE", () => {
      setActive(false);
    });
    return () => {
      PubSub.unsubscribe(token);
    };
  }, []);

  useEffect(() => {
    const token1 = PubSub.subscribe("MENU_CATEGORY_OPEN", (e) => {
      // console.log(e);
      setActive(true);
    });

    const token2 = PubSub.subscribe("MENU_CATEGORY_TOGGLE", () => {
      // console.log("active", active);
      setActive(!active);
    });
    return () => {
      PubSub.unsubscribe(token1);
      PubSub.unsubscribe(token2);
    };
  }, [active]);

  useEffect(() => {
    // console.log(direction);
    if ("ontouchstart" in window || navigator.maxTouchPoints) return;
    if (direction === "up") setActive(false);
  }, [direction]);

  useEffect(() => {
    if ("ontouchstart" in window || navigator.maxTouchPoints) {
      if (active)
        document.querySelector("header").classList.add("is-mobile-open");
      else document.querySelector("header").classList.remove("is-mobile-open");
    }
  }, [active]);

  // useEffect(() => {}, [currentCategory]);
  const _getAllItemCategories = (item) => {
    return item.data.categories.map(({ category }) => category.uid);
  };
  const _renderAllItemCategories = (item) => {
    return item.data.categories.map(({ category }) => category.uid).toString();
  };

  // console.log(prismicMenu);
  return (
    <Wrapper
      active={active}
      className={clsx(
        "flex items-start col-category-wrapper",
        active ? "is-active" : ""
      )}>
      <div
        className='col-category pr-sm'
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}>
        <div className='btn-toggle-wrapper mb-sm whitespace-nowrap'>
          <button
            id='toggle'
            className='block'
            onClick={() => setActive(!active)}
            tabIndex='1'>
            {template === "template-category"
              ? currentCategoryTitle
              : "Selected"}
          </button>
        </div>
        <Nav id='menu-category' className=''>
          <ul>
            <Tween
              duration={duration}
              from={{ y: "-10px", opacity: 0 }}
              to={{ y: "0", opacity: 1 }}
              stagger={0.4}
              ease={ease}
              playState={active ? "play" : "reverse"}>
              {prismicMenu.data.menu_items.map((li, i) => (
                <li
                  key={i}
                  onMouseEnter={() => setCurrentCategory(li.item.uid)}
                  onMouseLeave={() => setCurrentCategory("")}
                  style={{ paddingBottom: li.margin || 0 }}>
                  <Link to={_linkResolver(li.item)} tabIndex={i + 2}>
                    {li.title.text}
                  </Link>
                </li>
              ))}
            </Tween>
          </ul>
        </Nav>
        {/* currentCategory: {currentCategory} */}
      </div>
      <nav id='menu-projects' className='col-projects hidden-sm'>
        <ul className=''>
          {allPrismicProject.nodes.map((item, i) => (
            <li
              key={i}
              className={clsx(
                "menu-projects__item",
                _getAllItemCategories(item).includes(currentCategory)
                  ? "opacity-20 "
                  : "opacity-0 "
              )}
              data-categories={_renderAllItemCategories(item)}>
              <div className='flex '>
                <div className='index pr-sm num'>{pad2(i + 1)}</div>
                <div className='client pr-sm'>{item.data.client}</div>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </Wrapper>
  );
};

export default MenuCategory;
