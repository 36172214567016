import React, { useRef, useEffect, Fragment } from "react";
import ProjectsIndexItemImage from "./ProjectsIndexItemImage";
import ProjectsIndexItemVideo from "./ProjectsIndexItemVideo";

const ProjectsIndexGrid = ({ input }) => {
  // console.log(input);
  const wrapperRef = useRef();
  // const { currentCategory } = useContext(CategoryContext);
  // console.log("input", input);

  useEffect(() => {
    // _format();
    setTimeout(() => {
      _format();
    }, 250);

    window.addEventListener("resize", _format, false);
    // window.addEventListener("resize", _format);
    return () => window.removeEventListener("resize", _format, false);
  }, []);

  // const { ref, inView, entry } = useInView({
  //   /* Optional options */
  //   threshold: 0,
  // });

  let cachedWindowWidth = 0;
  //resize each rows
  const _format = () => {
    // console.log("_format", cachedWindowWidth);
    // console.log(prevWindowWidth, window.innerWidth);
    //test pour mobile au scroll ios çà trigger le resize

    // return;
    const figure = wrapperRef.current.querySelectorAll("figure.is-ready");
    if (figure.length === 0) {
      setTimeout(() => {
        _format();
      }, 50);
      return;
    }
    // console.log(figure.length);
    const wrapperWidth = wrapperRef.current.getBoundingClientRect().width;

    //prevent redraw, recalc
    if (cachedWindowWidth === window.innerWidth) return;
    cachedWindowWidth = window.innerWidth;

    let row = [];
    let rowIndex = 0;
    let rowWidth = 0;

    // console.log(figure[0].dataset.width);
    // if (parseFloat(figure[0].dataset.width) === 0) {
    //   setTimeout(() => {
    //     _format();
    //   }, 50);
    //   return;
    // }

    // console.log(figure);
    figure.forEach((el, index) => {
      const { width } = el.getBoundingClientRect();

      //tableau de la ligne que l'on analyse
      row.push(el);
      rowWidth += width;
      // console.log(wrapperWidth, rowWidth);
      if (rowWidth >= wrapperWidth) {
        const divider = wrapperWidth / rowWidth;
        // console.log(`New row ${rowIndex} - divider: ${divider}`);
        row.forEach((item) => {
          const imageWidth = parseFloat(item.dataset.width) * divider;
          // console.log(item.dataset, imageWidth);
          item.style.width = imageWidth + "px";
          item.dataset.row = index;
          item.dataset.rowIndex = rowIndex;
          item.dataset.divider = divider;
        });

        row = [];
        rowIndex++;
        rowWidth = 0;
      }
    });
  };
  // console.log(input[0]);
  return (
    <div className='inner ' ref={wrapperRef}>
      <ul className='flex flex-wrap items-baseline '>
        {input.map((el, i) => (
          <Fragment key={i}>
            {el.video ? (
              <ProjectsIndexItemVideo input={el} key={i} index={i} />
            ) : (
              <ProjectsIndexItemImage input={el} key={i} index={i} />
            )}
          </Fragment>
        ))}
      </ul>
    </div>
  );
};
export default ProjectsIndexGrid;
