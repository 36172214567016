import React, { useState, useEffect } from "react";
import { usePalette } from "react-palette";
import PubSub from "pubsub-js";
import clsx from "clsx";
import { Link } from "gatsby";

const ProjectsIndexItemVideo = ({ input, index }) => {
  // console.log(input);
  const [ready, setReady] = useState();
  const [active, setActive] = useState();
  const [dimensions, setDimensions] = useState({ with: 0, height: 0 });

  useEffect(() => {
    setDimensions(_setImgRelativeDimensions());
    // dimensions = _setImgRelativeDimensions();
    setReady(true);

    const token = PubSub.subscribe("PROJECT-ITEM-HOVER", (e, d) => {
      // console.log(input.uid, d);
      if (d.uid === input.uid) setActive(d.hover);
      else setActive(false);
    });

    return () => PubSub.unsubscribe(token);
  }, []);

  const _getRawDimensions = () => {
    const parts = input.aspect_ratio
      ? input.aspect_ratio.split(":")
      : [1944, 1080];
    return { rawWidth: parts[0], rawHeight: parts[1] };
  };

  const _getAspectRatio = () => {
    const { rawWidth, rawHeight } = _getRawDimensions();
    return rawWidth / rawHeight;
  };

  const _setImgRelativeDimensions = () => {
    if (typeof window === "undefined") return { width: 0, height: 0 };

    const maxWidth = 120;
    const { innerWidth } = window;
    const innerWidthPerc = (innerWidth * 10) / maxWidth;

    const { rawWidth, rawHeight } = _getRawDimensions();

    const width =
      (rawWidth * innerWidthPerc) / innerWidth < maxWidth
        ? (rawWidth * innerWidthPerc) / innerWidth
        : maxWidth;
    const height = width / aspectRatio;
    // console.log("_setImgRelativeDimensions", width);
    return { width, height };
  };

  const _getMainCategory = () => {
    return input.categories.filter((el) => el.category_principale);
  };

  const _getCategoryUrlWithAnchor = () => {
    const mainCategory = _getMainCategory()[0];
    return `/category/${mainCategory.category.document.uid}/#${input.uid}`;
  };

  const aspectRatio = input.aspect_ratio ? _getAspectRatio() : 1944 / 1080;
  // console.log(aspectRatio, dimensions.width);
  const style = {
    width: dimensions.width,
  };

  // console.log(_getImgRelativeDimensions(input.image));
  // console.log(input);
  return (
    <li
      className={clsx(
        `project-item-${input.uid}`,
        "project-item project-item--video mb-xs md:mb-md flex-shrink-0 inline-block",
        active ? "is-active" : ""
      )}
      onMouseEnter={() =>
        PubSub.publish("PROJECT-ITEM-HOVER", { hover: true, uid: input.uid })
      }
      onMouseLeave={() =>
        PubSub.publish("PROJECT-ITEM-HOVER", { hover: false, uid: input.uid })
      }>
      <Link to={_getCategoryUrlWithAnchor()}>
        {/* {ready && (
          <> */}
        <figure
          className={clsx(ready ? "is-ready" : "")}
          style={style}
          data-width={dimensions.width}
          data-ratio={aspectRatio}>
          <div>
            <div
              className='bg absolute top-0 left-0 w-full h-full z-0'
              style={
                {
                  // backgroundImage: `linear-gradient(to bottom, ${lightVibrant}, ${lightMuted})`,
                }
              }></div>
            <video src={input.video} autoPlay loop muted></video>
          </div>
        </figure>
        {input.figIndex && (
          <figcaption className='absolute py-xs text-xs italic text-secondary '>
            fig {input.figIndex}.1
          </figcaption>
        )}
        {/* {input.image.url} */}
        {/* </>
        )} */}
      </Link>
    </li>
  );
};

export default ProjectsIndexItemVideo;
