import React, { useContext } from "react";
//import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";
import clsx from "clsx";
// import PubSub from "pubsub-js";
import Menu from "./Menu";
// import Burger from "./ui/Burger";
import { WrapperContext } from "./Layout";
import MenuCategory from "./MenuCategory";
// import { useEffect } from "react";
// import { useState } from "react";

const Header = () => {
  const _WrapperContext = useContext(WrapperContext);
  const { settings, template, h1 } = _WrapperContext;

  // useEffect(() => {
  //   const token = PubSub.subscribe("CURRENT_MENU_TEXT", (e, d) => {
  //     setCurrentMenu(d);
  //   });
  //   return () => PubSub.unsubscribe(token);
  // }, []);

  //le toggle du menu ne se fait que sur la page projects
  // const _onClick = () => {
  //   if (h1 === "PROJECTS") PubSub.publish("MENU_CATEGORY_TOGGLE");
  //   // console.log(currentMenu);
  // };

  const _isProjects = () =>
    template === "template-home" ||
    template === "template-category" ||
    template === "template-project";

  return (
    <header className={clsx("px-sm md:px-md z-51 md:z-50 ")}>
      <div className='inner'>
        <div
          className='row custom-gutter items-baseline'
          style={{
            "--custom-gutter": "17px",
            // "--custom-gutter": "22px",
          }}>
          <div className='col-auto col-site-name'>
            <Link to='/' tabIndex='0'>
              <span className='site-name uppercase '>
                {settings.data.title.text}
              </span>
            </Link>
          </div>
          <div className='col-auto col-h1'>
            {h1 && (
              <h1 className={clsx("current-menu-placeholder uppercase")}>
                {h1}
              </h1>
            )}
          </div>
          <div className='col-auto col-menu'>
            {_isProjects() && <MenuCategory />}
          </div>
        </div>
        <Menu />
      </div>
    </header>
  );
};

export default Header;
