import React, { useContext } from "react";
import { WrapperContext } from "../components/Layout";

export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url);
  return decoded.substring(decoded.lastIndexOf("/") + 1);
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function _linkResolver(doc) {
  // console.log(doc);
  switch (doc.type) {
    case "home":
      return `/`;

    case "project":
      return _getCategoryUrlWithAnchor(doc);

    case "category":
      return `/category/${doc.uid}`;

    case "product":
      return `/product/${doc.uid}`;

    default:
      return `/${doc.uid}`;
  }
}

const _getMainCategory = (input) => {
  return input.data.categories.filter((el) => el.category_principale);
};

const _getCategoryUrlWithAnchor = (input) => {
  console.log(input);
  if (!input.data) return "/";
  const mainCategory = _getMainCategory(input)[0];
  return `/category/${mainCategory.category.document.uid}/#${input.uid}`;
};

export function pad2(n) {
  return n < 10 ? `0${n}` : n;
}

export function _getPaddingCss(padding, unit = "px") {
  if (!padding) return 0;

  const { isMobile } = useContext(WrapperContext);
  // console.log(padding);
  const parts = padding.replace(" ", "").split(",");
  let css = "";
  parts.forEach((el, i) => {
    let val = isMobile ? el / 2 : el;

    let _unit = unit;
    if (unit === "vw") {
      _unit = isOdd(i) ? "vw" : "vh";
    }
    // css += `${el}${_unit} `;
    css += `${val}${_unit} `;
  });
  return css;
}

export function getMinAspectRatio(containerWidth) {
  if (containerWidth <= 800) return 1.5;
  if (containerWidth <= 1280) return 3;
  if (containerWidth <= 1920) return 4;
  return 5;
}

function isOdd(num) {
  return num % 2;
}

export const debounce = (func, wait, immediate) => {
  var timeout;
  return () => {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
