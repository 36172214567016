import React, { useState, useEffect } from "react";
import { pad2 } from "../../core/utils";

const Clock = () => {
  const [date, setDate] = useState("00.00");
  const [time, setTime] = useState("00.00");

  useEffect(() => {
    const timer = setInterval(_update, 1000);
    // _start();
    return () => clearInterval(timer);
  }, []);

  const _update = () => {
    const now = new Date();

    const day = pad2(now.getDate());
    const month = pad2(now.getMonth() + 1);
    const hour = pad2(now.getHours());
    const minute = pad2(now.getMinutes());

    setDate(`${day}.${month}`);
    setTime(`${hour}:${minute}`);
    // console.log(now);
  };

  return (
    <section className='clock text-lg text-center text-secondary'>
      <span className='px-xxs'>{date}</span>{" "}
      <span className='px-xxs'>{time}</span>
    </section>
  );
};

export default Clock;
