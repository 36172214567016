import React, { useEffect, useRef, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
// import { _linkResolver } from "../core/utils";

// import { useInView } from "react-intersection-observer";
import PubSub from "pubsub-js";
import clsx from "clsx";
// import { Tween } from "react-gsap";
// import Pre from "./utils/Pre";
// import { CategoryContext } from "./Layout";
// import { debounce } from "../core/utils";
import ProjectsIndexGrid from "./ProjectsIndexGrid";

const query = graphql`
  query {
    allPrismicProject(sort: { fields: data___order }) {
      nodes {
        uid
        type
        data {
          title {
            text
          }
          categories {
            category_principale
            category {
              document {
                ... on PrismicCategory {
                  uid
                }
              }
            }
          }
          body {
            ... on PrismicProjectBodyImagesScroller {
              slice_type
              items {
                image {
                  ...sharpThumbnail
                }
              }
            }
            ... on PrismicProjectBodyImage {
              slice_type
              primary {
                image {
                  ...sharpThumbnail
                }
              }
            }
            ... on PrismicProjectBodyImageFull {
              slice_type
              primary {
                image {
                  ...sharpThumbnail
                }
              }
            }
            ... on PrismicProjectBodyVideo {
              slice_type
              primary {
                padding
                aspect_ratio
                video {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ProjectsIndex = () => {
  const { allPrismicProject } = useStaticQuery(query);

  const _getAllImages = (node, i) => {
    const bodyClean = node.data.body.filter(
      (val) => Object.entries(val).length !== 0
    );
    let images = bodyClean.map((slice, i) =>
      _getImagesBySliceType(slice, node, i)
    );
    // console.log(bodyClean);
    images[0][0].figIndex = i + 1;
    // console.log(images[0][0]);
    return [].concat(...images);
  };

  const _getImagesBySliceType = (slice, node, index) => {
    // if (slice.slice_type === "video") console.log(slice);
    switch (slice.slice_type) {
      case "images_scroller":
        return slice.items.map(({ image }) => {
          // console.log(image);
          return {
            image,
            type: node.type,
            uid: node.uid,
            title: node.data.title.text,
            categories: node.data.categories,
            index: index,
          };
        });
      case "image":
        return [
          {
            image: slice.primary.image,
            type: node.type,
            uid: node.uid,
            title: node.data.title.text,
            categories: node.data.categories,
            index: index,
          },
        ];
      case "image_full":
        return [
          {
            image: slice.primary.image,
            type: node.type,
            uid: node.uid,
            title: node.data.title.text,
            categories: node.data.categories,
            index: index,
          },
        ];
      case "video":
        return [
          {
            image: null,
            video: slice.primary.video.url,
            aspect_ratio: slice.primary.aspect_ratio,
            type: node.type,
            uid: node.uid,
            title: node.data.title.text,
            categories: node.data.categories,
            index: index,
          },
        ];
      default:
        return null;
    }
  };

  const _processImages = () => {
    let flatten = allPrismicProject.nodes.map((el, i) => {
      // console.log(el)
      return _getAllImages(el, i);
    });
    return [].concat(...flatten);
  };
  let flatten = _processImages();
  // console.log(flatten);

  return (
    <section className='projects-index p-sm md:p-md pt-lg w-screen'>
      <h2 className=''>Index</h2>
      <ProjectsIndexGrid input={flatten} />
    </section>
  );
};

export default ProjectsIndex;
