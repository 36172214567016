import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import Img from "gatsby-image";
import Clock from "./ui/Clock";
import { Tween } from "react-gsap";
import gsap from "gsap";
import { _linkResolver } from "../core/utils";

const query = graphql`
  query {
    prismicIntro {
      data {
        texte_top {
          raw
        }
        texte_bottom {
          raw
        }
        logos {
          logo {
            ...sharp
          }
          width
        }
      }
    }
  }
`;

const Intro = () => {
  const { prismicIntro } = useStaticQuery(query);
  const { texte_top, texte_bottom, logos } = prismicIntro.data;
  const [randomLogo, setRandomLogo] = useState();
  const [ready, setReady] = useState();

  const animationDelay = 1000;
  const animationDuration = 10;

  useEffect(() => {
    setRandomLogo(_getRandomImage());
  }, []);

  useEffect(() => {
    setTimeout(() => setReady(true), animationDelay);
  }, [randomLogo]);

  const _getRandomImage = () => {
    const idx = Math.round(Math.random() * (logos.length - 1));

    return logos[idx];
  };

  const _goHome = () => {
    // console.log("go home");
    const target = document.querySelector("header");
    if (target) {
      // elementScrollIntoViewPolyfill(
      //   target.scrollIntoView({
      //     behavior: "smooth",
      //     block: "start",
      //     inline: "start",
      //   })
      // );
      const bounding = target.getBoundingClientRect();
      // console.log(bounding);
      gsap.to(window, {
        duration: 0.6,
        scrollTo: { y: bounding.top, x: 0 },
      });
    }
  };
  // const randomLogo = _getRandomImage();
  // console.log(texte_top);
  // console.log(randomLogo);

  return (
    <section className='intro relative min-h-screen hidden-sm-'>
      <Tween
        from={{ y: "0" }}
        to={{ y: "-100%" }}
        duration={animationDuration}
        ease='ease'
        playState={ready ? "play" : "reverse"}>
        <div
          className='bg  w-screen h-screen- top-0 left-0 fixed z-0 bg-gray'
          onClick={() => _goHome()}></div>
      </Tween>

      <Tween
        from={{ y: "0" }}
        to={{ y: "-100%" }}
        duration={animationDuration}
        ease='ease'
        playState={ready ? "play" : "reverse"}>
        <div className='logo-wrapper  w-screen h-screen- top-0 left-0 fixed z-50'>
          {randomLogo && (
            <div className='logo w-screen h-screen  absolute top-1/2 flex justify-center items-start '>
              <div style={{ width: `${randomLogo.width}vw` }}>
                <Img {...randomLogo.logo} />
              </div>
            </div>
          )}
        </div>
      </Tween>

      <div className='flex flex-col items-center justify-center min-h-screen h-full p-md text-center'>
        <div className='texte'>
          <RichText render={texte_top.raw} linkResolver={_linkResolver} />
        </div>
        <div className='p-sm'>
          <Clock />
        </div>
        <div className='texte'>
          <RichText render={texte_bottom.raw} linkResolver={_linkResolver} />
        </div>
      </div>
    </section>
  );
};

export default Intro;
