import React, { useContext, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import clsx from "clsx";
import { _linkResolver } from "../core/utils";
import { WrapperContext } from "./Layout";

const query = graphql`
  query {
    prismicMenu(uid: { eq: "menu_header" }) {
      data {
        menu_items {
          item {
            type
            uid
            target
            url
          }
          title {
            text
          }
          accr
          margin
        }
      }
    }
  }
`;

const Menu = () => {
  const { path, template } = useContext(WrapperContext);
  const { prismicMenu } = useStaticQuery(query);

  useEffect(() => {
    setTimeout(() => {
      const curremt = document.querySelector(".current-menu-item");
      if (!curremt) return;
      curremt.style.order = -1;

      // console.log(currentText);
      // curremt.style.flexGrow = 3;

      const currentText = curremt.querySelector(".title").innerText;
      PubSub.publish("CURRENT_MENU_TEXT", currentText);

      // curremt.querySelector(".accr").style.opacity = 0;
      // curremt.querySelector(".title").style.opacity = 1;
    }, 1000);
  }, [path]);

  // const _isProjects = () =>
  //   template === "template-home" ||
  //   template === "template-category" ||
  //   template === "template-project";

  // const _isCurrentLink = (linkPath) => {
  //   // console.log(path, linkPath);
  //   if (!path) return;
  //   // if (path.indexOf("project") > -1 && linkPath === "/") return true;
  //   // if (path.indexOf("category") > -1 && linkPath === "/") return true;
  //   return path === linkPath;
  // };
  // console.log(prismicMenu);

  return (
    <nav id='menu-header' className='absolute right-0 top-0'>
      <ul className='flex justify-end'>
        {prismicMenu.data.menu_items.map((li, i) => (
          <li
            key={i}
            className={clsx(
              "menu-item ",
              li.item.target !== "_blank"
                ? `menu-${li.item.uid}`
                : "external-link"
            )}>
            {li.item.target === "_blank" ? (
              <a
                href={li.item.url}
                target='_blank'
                rel='noopener, noreferrer'
                style={{ paddingLeft: li.margin || 17 }}>
                <div className='accr'>{li.accr}</div>
                <div className='title uppercase'>{li.title.text}</div>
              </a>
            ) : (
              <Link
                to={`${_linkResolver(li.item)}`}
                style={{ paddingLeft: li.margin || 17 }}>
                <div className='accr'>{li.accr}</div>
                <div className='title uppercase'>{li.title.text}</div>
              </Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
