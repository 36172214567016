import React, { useState, useEffect } from "react";
import PubSub from "pubsub-js";
import clsx from "clsx";
import { Link } from "gatsby";

const ProjectsIndexItemImage = ({ input, index }) => {
  // console.log(input);
  const [ready, setReady] = useState();
  const [active, setActive] = useState();
  const [dimensions, setDimensions] = useState({ with: 0, height: 0 });

  const { image } = input;
  const aspectRatio = input.image.fluid?.aspectRatio;
  const isSvg = input.image.url.indexOf("svg") > -1;
  // let dimensions = { width: 0, height: 0 };

  useEffect(() => {
    setDimensions(_setImgRelativeDimensions());
    // dimensions = _setImgRelativeDimensions();
    setReady(true);

    const token = PubSub.subscribe("PROJECT-ITEM-HOVER", (e, d) => {
      // console.log(input.uid, d);
      if (d.uid === input.uid) setActive(d.hover);
      else setActive(false);
    });

    return () => PubSub.unsubscribe(token);
  }, []);

  const _setImgRelativeDimensions = () => {
    if (typeof window === "undefined") return { width: 0, height: 0 };

    const isPortrait = aspectRatio < 1;
    const maxWidth = isSvg ? 120 : isPortrait ? 50 : 100;

    if (isSvg) {
      // console.log(image.dimensions);
      const width =
        image.dimensions.width < maxWidth ? image.dimensions.width : maxWidth;
      const height = width * aspectRatio;

      return { width, height };
    }

    const { innerWidth } = window;
    const innerWidthPerc = (innerWidth * 10) / maxWidth;
    // let width = (image.dimensions.width * innerWidthPerc) / innerWidth;
    // if (width > maxWidth) width = maxWidth;
    const width =
      (image.dimensions.width * innerWidthPerc) / innerWidth < maxWidth
        ? (image.dimensions.width * innerWidthPerc) / innerWidth
        : maxWidth;
    const height = width / aspectRatio;
    // console.log("_setImgRelativeDimensions", width);
    return { width, height };
  };

  const _getMainCategory = () => {
    const hasMainCategory = input.categories.filter(
      (el) => el.category_principale
    );
    // console.log(hasMainCategory.length);
    return hasMainCategory.length ? hasMainCategory[0] : input.categories[0];
  };

  const _getCategoryUrlWithAnchor = () => {
    const mainCategory = _getMainCategory();
    // console.log(input.title, mainCategory);
    if (mainCategory)
      return `/category/${mainCategory.category.document.uid}/#${input.uid}`;
  };

  // const { data, loading, error } = usePalette(image.url);
  // const { lightVibrant, lightMuted } = data;

  const style = {
    width: dimensions.width,
    // maxWidth: "20vw",
  };

  // console.log(dimensions);

  const start = "#bcbebe";
  const end = "#ffffff";

  return (
    <li
      className={clsx(
        `project-item-${input.uid}`,
        "project-item project-item--image mb-xs md:mb-md flex-shrink-0 inline-block",
        active ? "is-active" : ""
      )}
      onMouseEnter={() =>
        PubSub.publish("PROJECT-ITEM-HOVER", { hover: true, uid: input.uid })
      }
      onMouseLeave={() =>
        PubSub.publish("PROJECT-ITEM-HOVER", { hover: false, uid: input.uid })
      }>
      <Link to={_getCategoryUrlWithAnchor()}>
        <figure
          className={clsx(ready ? "is-ready" : "")}
          style={style}
          data-ratio={aspectRatio}
          data-width={dimensions.width}
          data-height={dimensions.height}>
          <div>
            <div
              className={clsx("bg absolute top-0 left-0 w-full h-full z-0")}
              style={{
                backgroundImage: `linear-gradient(to bottom, ${start}, ${end})`,
              }}></div>
            <img
              src={image.fluid.src}
              srcSet={image.fluid.srcSet}
              alt={image.alt}
              loading='lazy'
              className={clsx(
                // inView ? "opacity-100" : "opacity-0",
                "opacity-100 relative z-1",
                isSvg ? "bg-white" : ""
              )}
              style={{ transitionDelay: `${index * 40}ms` }}
              // onLoad={() => setReady(true)}
            />
          </div>
        </figure>
        {input.figIndex && (
          <figcaption className='absolute py-xs text-xs italic text-secondary '>
            fig {input.figIndex}.1
          </figcaption>
        )}
      </Link>
    </li>
  );
};

export default ProjectsIndexItemImage;
