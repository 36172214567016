import React, {
  useState,
  useEffect,
  createContext,
  lazy,
  Suspense,
} from "react";
import { useStaticQuery, graphql } from "gatsby";
// import { useLocation } from "@reach/router";
import Intro from "./Intro";
import Header from "./Header";
import ProjectsIndex from "./ProjectsIndex";
// import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";
// import { polyfill } from "seamless-scroll-polyfill";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

// if (process.client) {
//   gsap.registerPlugin(ScrollToPlugin);
// }

const WrapperContext = createContext();
// const CategoryContext = createContext();

const query = graphql`
  query {
    settings: prismicSettings {
      ...settings
    }
  }
`;

const Layout = ({ children, location, path, pageContext }) => {
  // console.log(template);
  const { settings } = useStaticQuery(query);
  // const location = useLocation();
  // const [currentCategory, dispatchCurrentCategory] = useState();
  const [direction, setDirection] = useState();
  const [isMobile, setIsMobile] = useState(null);
  const { template, h1, showProjectsIndex, currentCategoryTitle } = pageContext;

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin);
    // console.log("ready");

    _scroll();

    _format();
    setTimeout(() => {
      _format();
    }, 250);

    window.addEventListener("resize", _format);
    window.addEventListener("scroll", _scroll);

    return () => {
      window.removeEventListener("resize", _format);
      window.removeEventListener("scroll", _scroll);
    };
  }, []);

  useEffect(() => {
    if (
      ("ontouchstart" in window || navigator.maxTouchPoint) &&
      window.innerWidth < 1280
    ) {
      //1112
      setIsMobile(true);
    }
    // console.log(window.innerWidth);
  }, [setIsMobile]);

  useEffect(() => {
    if (location.hash) {
      // console.log(`The current URL hash is ${location.hash}`);
      setTimeout(() => {
        _format();
        _scrollToHash();
      }, 1000);
    }
  }, [location]);

  const _scrollToHash = () => {
    const targetID = location.hash.replace("#", "");
    const target = document.getElementById(targetID);
    if (target) {
      console.log("_scrollToHash", targetID);
      // console.log(target);
      gsap.to(window, { duration: 0.5, scrollTo: `#${targetID}` });
    }
  };

  const _format = () => {
    const doc = document.documentElement;
    const header = document.querySelector("header");

    doc.style.setProperty("--app-height", window.innerHeight + "px");

    // const headerBounding = header.getBoundingClientRect();
    doc.style.setProperty("--header-height", 57 + "px");

    // const HeaderProjects = header.querySelector("nav#menu-projects ul");
    // if (HeaderProjects) {
    //   const HeaderProjectsBounding = HeaderProjects.getBoundingClientRect();
    //   doc.style.setProperty(
    //     "--header-project-item-left",
    //     HeaderProjectsBounding.left + "px"
    //   );
    //   doc.style.setProperty(
    //     "--header-project-item-width",
    //     HeaderProjectsBounding.width + "px"
    //   );
    // }
    const HeaderMenuProjects = header.querySelector(
      "header .col-category-wrapper"
    );
    if (HeaderMenuProjects) {
      const HeaderMenuProjectsBounding =
        HeaderMenuProjects.getBoundingClientRect();
      // console.log(HeaderMenuProjectsBounding);

      doc.style.setProperty(
        "--header-project-item-left",
        `${HeaderMenuProjectsBounding.left}px`
      );
      doc.style.setProperty(
        "--header-project-item-width",
        `${HeaderMenuProjectsBounding.width}px`
      );
    }
  };

  let _prevScrollTop = 0;
  const _scroll = () => {
    // console.log("_scroll", window.pageYOffset, _prevScrollTop);
    window.pageYOffset > _prevScrollTop
      ? setDirection("down")
      : setDirection("up");

    if (window.pageYOffset === 0) {
      setDirection("");
    }

    const gap = "ontouchstart" in window || navigator.maxTouchPoints ? 50 : 0;
    const distanceToBottom =
      document.body.scrollHeight - (window.innerHeight + window.scrollY);

    // console.log(distanceToBottom);
    if (distanceToBottom <= gap) {
      PubSub.publish("SCROLL_BOTTOM");
    }

    _prevScrollTop = window.pageYOffset;
  };

  return (
    <WrapperContext.Provider
      value={{ settings, path, ...pageContext, direction, isMobile }}>
      <div id='page'>
        {template === "template-home" && <Intro />}
        <Header />
        <main>{children}</main>
        {showProjectsIndex && <ProjectsIndex />}
        {/* {showProjectsIndex && (
          <Suspense fallback={<div>Loading...</div>}>
            <ProjectsIndexLazy />
          </Suspense>
        )} */}
        {/* <CursorArrow /> */}
        {/* <Cursor /> */}
      </div>
    </WrapperContext.Provider>
  );
};

export { WrapperContext, Layout };
